.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-switch-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 10px;
}

.toggle-container {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjusted width */
  height: 20px; /* Adjusted height */
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px; /* Adjusted border-radius */
}

.toggle-input:checked + .toggle-slider {
  background-color: #4caf50;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjusted size */
  width: 16px; /* Adjusted size */
  left: 2px; /* Adjusted position */
  bottom: 2px; /* Adjusted position */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-input:checked + .toggle-slider:before {
  transform: translateX(16px); /* Adjusted translation */
}
