@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.react-datepicker__input-container input {
  @apply w-full text-base md:text-sm bg-white p-1 rounded 
}.proPages {
  display: grid;
  grid-template-columns: 235px auto;
  position: relative;
}.personalFileTabs {
  margin-top: -63px;
  z-index: 0;
  position: absolute;
}.navBg {
  /* border-top: 3px solid #c0e94f; */
  height: 180px;
  margin-top: 4px;
  /* z-index: -1; */
}.gradient-border {
  background-image: linear-gradient(to right, #caf359, #89bb00);
}.mainInfo{
  height: 100vh;
    z-index: 0; position: fixed; right: 162px; top:258px ;width: 225px;}.MainContainer {
  width: 80%;
  justify-content:end ; 
}.mainInfo2{
    height: 100%;
    z-index: 0; position: absolute; right: 162px; top:60px ;width: 225px;}.MainContainer {
  width: 80%;
  justify-content:end ; 

  }.MainContainer {
  width: 80%;
  justify-content:end ;
}.mainInfo3{  
    right: 388px;
    top:258px }
    .MainContainer {
  width: 80%;
  justify-content:end ;
}.circle-container {
  width: 200px; /* Adjust the size of your circular container */
  height: 200px;
  border-radius: 50%; /* Creates a circular shape */
  overflow: hidden; /* Hides the overflow from the circular container */
  /* right:170px; */
  top: 85px;
  z-index: 10;
  position: absolute;
  border-color: rgb(255, 255, 255);
  border-width: 4px;
  }




.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the circular container */
  border: 2px solid #fff; /* Add a border if desired */
  z-index: 100;
  /* left: 180px; */
  right: 0px;
  position: absolute;

}

.ulPersonInfo{
  margin-top: 70px;
  margin-right: 10px; 
  margin-left: 10px; 
}
.name{
  margin-right: -260px; 
  
}.logo{
  font-size: 45px;
  font-weight: 700;
  padding: 0px  50px 0px 50px ; 
}.mainInfoNav {
  display: grid;
  grid-template-columns: 300px auto;
  position: relative;
}
.saveButton{
  position: absolute;
  width: 90%;
  height: 150px;
  top:  100%;
  right: 385px;
  position: fixed;  
  transition: top 0.5s ease; /* Add the transition effect */
  border-top: 2px solid rgb(208, 208, 208);
}
 
  .saveButton.active {
    top:  85%;
  }

  .saveButton2{
    position: absolute;
    width: 110%;
    height: 40%;
    top:  100%;
    right: -50px;
    position: fixed;  
    transition: top 0.5s ease; /* Add the transition effect */
    border-top: 2px solid rgb(208, 208, 208);
  }

    .saveButton2.active {
      top:  85%;
    }

    .saveButton3{
      position: absolute;
      width: 110%;
      height: 30%;
      top:  100%;
      right: -50px;
      position: fixed;  
      transition: top 0.5s ease; /* Add the transition effect */
      border-top: 2px solid rgb(208, 208, 208);
    }
  
      .saveButton3.active {
        top:  85%;
      }
    .toastShow{
      position: fixed;  
      transition: top 0.5s ease; /* Add the transition effect */
      top:  100%;
    }
    .saveChangContainers{
    margin-right: 20%;
    margin-left: 25%;
    }
    .toastShow.active{
      top:  75%;
    }
    .toastShow.active2{
      top:  54%;
      right: 25%;
      left: 25%;
    }
    .toastShow.active3{
      top:  68%;
      right: 25%;
      left: 25%;
    }
  .dropdown{
    position: absolute;
    left: 180px;
    top: 10px;
  }
  .coloreButton:hover{
    background-color: transparent;
    color: #6a950a;
  }
  

  
/* Style the button that opens the dropdown */
.dropbtn {
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown content when the button is clicked */
.dropdown:hover .dropdown-content {
  display: block;
}

.customModel{
  margin-bottom: 10px;
  position: absolute;
}

    .parentPages{
      position: relative;
      background-color: #6a950a;
    }

    .customBorder::after{
      content: "";
      width: 88%;
      height: 2px;
      position: absolute;
      background-color: #acacac;
      top: 60px;
      margin-right: 162px;

    }

    .mainInfoAr{
      height: 100vh;
        z-index: 0; position: fixed; left: 162px; top:258px ;width: 225px;}.MainContainer {
      width: 80%;
      justify-content:end ; 
    }.mainInfo2Ar{
        height: 100%;
        z-index: 0; position: absolute; left: 162px; top:60px ;width: 225px;}.MainContainer {
      width: 80%;
      justify-content:end ; 
    
      }
      .mainInfo3Ar{  
        left: 388px;
        top:258px }
        .scrollable{
          max-width: 100%;         
          overflow-x: scroll;
         
        }
        .scroll-y {
          height: 120vh;
          overflow-y: scroll;
        }
        .List{
          display: grid;
          grid-template-columns:repeat(auto-fill,minmax(250px,1fr)) ;
          row-gap: 0px;
          column-gap: 20px; 
        
        }     
        
        .Card {
          cursor: pointer;
          width: 250px;
          background-color: #ffffff;
          box-shadow: 0px 0px 20px rgb(196, 196, 196);
          border-radius: 10px;
          padding: 20px;
          margin: 10px;
          transition: 0.3s;
        }
        
        
        .Card:hover{
            transform: scale(1.1);
        }
        .Card div {
         text-align: center;
          font-size: 18px;
          margin: 10px 0px;
          font-weight: 500;
      }
      .request{
        margin-right: -50px;
        margin-top: -25px;
      }
      .requestTable{
        margin-top: -15px;
        margin-right: 25px;
      }
      .buttonLog {
        display: inline-block;
        padding: 5px 10px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        border: 2px solid #527a00;
        color: #527a00;
        
        border-radius: 5px;
        transition: background-color 0.3s, color 0.3s;
      }
      .buttonLog:hover {
        background-color: #527a00;
        color: #fff;
      }